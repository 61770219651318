<template>
  <div class="dynamic_layout">
    <img src="@/assets/dynamic_swiper.png" class="dynamic_swiper" />
    <div class="title">行业动态</div>
    <div class="list_layout">
      <div class="list_item" v-for="(item, index) in list" :key="index">
        <img :src="item.cover" class="list_cover" />
        <div class="item_detail">
          <div class="detail_title">
            <div>{{ item.title }}</div>
            <div>2024.3.4</div>
          </div>
          <div class="detail_desc" v-html="item.content" />
          <div class="btn">查看详情>></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getArticleList } from '@/api'
import { ElMessage } from 'element-plus'
import { ref } from 'vue'

const list = ref<Array>([])
  
const getArticleLists = async () => {
  const { data: res } = await getArticleList({ page: 1, pageSize: 999 })
  if (res.code === 1) {
    list.value = res.result.records
  } else {
    ElMessage.error(res.error && res.error.length ? res.error : '获取失败')
  }
}

getArticleLists()
</script>

<style lang="scss">
.dynamic_layout {
  width: 100%;
  .dynamic_swiper {
    width: 100%;
    height: auto;
  }
  .title {
    box-sizing: border-box;
    padding-top: 1.8927rem;
    font-size: 1.1987rem;
    max-width: 80%;
    min-width: 1200PX;
    margin: 0 auto;
    color: #0B151E;
    font-weight: 600;
  }
  .list_layout {
    max-width: 80%;
    min-width: 1200PX;
    margin: 0 auto;
    overflow: hidden;
    .list_item {
      display: flex;
      align-items: flex-start;
      padding: 1.2618rem 0;
      border-bottom: 1PX solid #E4E7ED;
      cursor: pointer;
      .list_cover {
        width: 10.41rem;
        min-width: 10.41rem;
        height: 6.309rem;
        object-fit: cover;
        margin-right: .6309rem;
      }
      .item_detail {
        flex: 1;
        .detail_title {
          padding-top: .317rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: .371rem;
          >div:nth-child(1) {
            font-size: 0.5678rem;
            font-weight: 600;
            color: #151D48;
            max-width: 33rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          >div:nth-child(2) {
            color: #737791;
            font-size: 0.44164rem;
          }
        }
        .detail_desc {
          font-size: .4416rem;
          line-height: .82rem;
          color: rgba(40, 41, 56, .6);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
          margin-bottom: 1.2618rem;
        }
        .btn {
          color: #5D5FEF;
          font-size: .44164rem;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
