import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/dynamic_swiper.png'


const _hoisted_1 = { class: "dynamic_layout" }
const _hoisted_2 = { class: "list_layout" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "item_detail" }
const _hoisted_5 = { class: "detail_title" }
const _hoisted_6 = ["innerHTML"]

import { getArticleList } from '@/api'
import { ElMessage } from 'element-plus'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DynamicView',
  setup(__props) {

const list = ref<Array>([])
  
const getArticleLists = async () => {
  const { data: res } = await getArticleList({ page: 1, pageSize: 999 })
  if (res.code === 1) {
    list.value = res.result.records
  } else {
    ElMessage.error(res.error && res.error.length ? res.error : '获取失败')
  }
}

getArticleLists()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("img", {
      src: _imports_0,
      class: "dynamic_swiper"
    }, null, -1)),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "title" }, "行业动态", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "list_item",
          key: index
        }, [
          _createElementVNode("img", {
            src: item.cover,
            class: "list_cover"
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, _toDisplayString(item.title), 1),
              _cache[0] || (_cache[0] = _createElementVNode("div", null, "2024.3.4", -1))
            ]),
            _createElementVNode("div", {
              class: "detail_desc",
              innerHTML: item.content
            }, null, 8, _hoisted_6),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "btn" }, "查看详情>>", -1))
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})