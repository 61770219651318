import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
import '@/utils/rem'
import 'reset-css'
import ElementPlusPlugin from '../plugins/element-plus'
import * as ElementPlusIcons from '@element-plus/icons-vue'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIcons)) {
  app.component(key, component)
}

if (!/Android|webOS|iPhone|iPod|BlackBerry|iPad/i.test(navigator.userAgent)) {
  // isMobile
}

app
.use(router)
.use(ElementPlusPlugin)
.mount('#app')
