import request from '@/utils/request'

interface dataInterface {
	code : number
	result ?: any
	message ?: string
	error ?: string
	status ?: number
}

interface requestType {
	data : dataInterface
}

const indexApi = {
  getArticleList: '/wwwArticle/f/list',
  getClientList: '/wwwPartners/f/list'
}

export function getArticleList (params: object, data:object = {}): Promise<requestType> {
  return request.request({
    url: indexApi.getArticleList,
    method: 'POST',
    params,
    data
  })
}

export function getClientList (params: object, data:object = {}): Promise<requestType> {
  return request.request({
    url: indexApi.getClientList,
    method: 'POST',
    params,
    data
  })
}
