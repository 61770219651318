import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import HeaderLayout from '@/components/headerLayout.vue'
import FooterLayout from '@/components/footerLayout.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderLayout),
    _createVNode(_component_router_view),
    _createVNode(FooterLayout)
  ], 64))
}
}

})