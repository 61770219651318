<template>
  <div class="index_layout">
    <img src="@/assets/index_swiper.png" class="index_swiper" />
    <div class="title">
      <span>我们的产品</span>
      <span>Our Products</span>
    </div>
    <div class="home_tab">
      <div class="tab_item">
        <img src="@/assets/home_icon1.png" alt="" class="home_icon1" />
        <div>花炮云</div>
        <div>文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案</div>
      </div>
      <div class="tab_item">
        <img src="@/assets/home_icon2.png" alt="" class="home_icon2" />
        <div>企业建站</div>
        <div>文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案</div>
      </div>
      <div class="tab_item">
        <img src="@/assets/home_icon3.png" alt="" class="home_icon3" />
        <div>ERP系统</div>
        <div>文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案文案</div>
      </div>
    </div>
    <div class="title" style="padding-bottom: 1.735rem;">
      <span>我们的优势</span>
      <span>Our advantages</span>
    </div>
    <div class="layout_item">
      <div class="layout_item_main" style="padding-bottom: 1.987rem;">
        <div class="desc">
          <div>TO 用户</div>
          <div>海量数据，精准定位</div>
          <div>定位周边烟花店铺，快速寻找</div>
          <div>大数据支持</div>
          <div>数据范围大，全国30w多家门店任您挑选</div>
        </div>
        <img src="@/assets/index_swiper1.png" class="index_swiper1" />
      </div>
    </div>
    <div class="layout_item">
      <div class="layout_item_main" style="padding: 1.4195rem 0;">
        <img src="@/assets/index_swiper2.png" class="index_swiper2" />
        <div class="desc">
          <div>TO 用户</div>
          <div>批发零售厂商，一网打尽</div>
          <div>批发零售厂商应有尽有，品类齐全</div>
          <div>品质店铺，权威认真，精准挑选</div>
          <div>店铺内产品信息一目了然，让您在购买前充分了解</div>
        </div>
      </div>
    </div>
    <div class="layout_item">
      <div class="layout_item_main" style="padding: 1.4195rem 0;">
        <div class="desc">
          <div>TO 店铺</div>
          <div>一键导入，快速便捷</div>
          <div>批发零售厂商应有尽有，品类齐全</div>
          <div>品质店铺，权威认真，精准挑选</div>
          <div>店铺资质齐全，一键导航可精准定位</div>
        </div>
        <img src="@/assets/index_swiper3.png" class="index_swiper3" />
      </div>
    </div>
    <div class="layout_item">
      <div class="layout_item_main" style="padding: 1.4195rem 0;">
        <img src="@/assets/index_swiper4.png" class="index_swiper4" />
        <div class="desc">
          <div>TO 店铺</div>
          <div>双端操作，随时办公</div>
          <div>电脑、手机双端操作，便捷管理</div>
          <div>操作简洁，实现高效率管理</div>
          <div>功能强大，高效的数据采集</div>
        </div>
      </div>
    </div>
    <div class="layout_item">
      <div class="layout_item_main">
        <div class="desc">
          <div>TO 店铺</div>
          <div>一键导入，快速便捷</div>
          <div>批发零售厂商应有尽有，品类齐全</div>
          <div>品质店铺，权威认真，精准挑选</div>
          <div>店铺资质齐全，一键导航可精准定位</div>
        </div>
        <img src="@/assets/index_swiper5.png" class="index_swiper5" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss">
.index_layout {
  .index_swiper {
    width: 100%;
    height: auto;
  }
  .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.514rem;
    padding: 2.5236rem 0 3.533rem;
    >span:nth-child(1) {
      position: relative;
      z-index: 9;
      color: #282938;
    }
    >span:nth-child(2) {
      position: absolute;
      top: 2.5236rem;
      left: 50%;
      transform: translate(-50%, 50%);
      color: #D5D6F3;
      opacity: .6;
    }
  }
  .home_tab {
    max-width: 80%;
    min-width: 1200PX;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;
    .tab_item {
      width: 12.776rem;
      padding: 1.577rem 1.2618rem;
      box-sizing: border-box;
      background-color: #F6F4FC;
      color: #282938;
      .home_icon1,
      .home_icon2,
      .home_icon3 {
        margin-bottom: .82rem;
      }
      .home_icon1 {
        width: 1.1211rem;
      }
      .home_icon2 {
        width: 1.009rem;
      }
      .home_icon3 {
        width: 1.1211rem;
      }
      >div:nth-child(2) {
        font-size: 0.757rem;
        margin-bottom: .378rem;
      }
      >div:nth-child(3) {
        font-size: 0.5047rem;
        line-height: .8832rem;
      }
    }
  }
  .layout_item {
    width: 100%;
    min-width: 1200PX;
    box-sizing: border-box;
    .layout_item_main {
      box-sizing: border-box;
      max-width: 80%;
      min-width: 1200PX;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      .index_swiper1 {
        width: 27.41324rem;
        height: auto;
      }
      .index_swiper2 {
        width: 24.858rem;
        height: auto;
      }
      .index_swiper3 {
        width: 22.3028rem;
        height: auto;
      }
      .index_swiper4 {
        width: 25.01577rem;
        height: auto;
      }
      .index_swiper5 {
        width: 28.95899rem;
        height: auto;
      }
      .desc {
        color: #151D48;
        >div:nth-child(1) {
          font-size: .5678rem;
          margin-bottom: .317rem;
        }
        >div:nth-child(2) {
          font-size: 1.1987rem;
          font-weight: 600;        
          margin-bottom: 1.2618rem;
        }
        >div:nth-child(3),
        >div:nth-child(4),
        >div:nth-child(5) {
          display: flex;
          align-items: center;
          font-size: .5678rem;
          color: rgba(21, 29, 72, .6);
          margin-bottom: .317rem;
          &::before {
            content: '';
            display: inline-block;
            width: 8PX;
            height: 8PX;
            border-radius: 50%;
            background-color: #5D5FEF;
            margin-right: .317rem;
          }
        }
        >div:nth-child(5) {
          margin-bottom: 0;
        }
      }
    }
    &:nth-child(2n + 2) {
      background-color: #F4F6FC;
      .layout_item_main {
        .desc {
          flex: 1;
          margin-left: 2.2713rem;
        }
      }
    }
  }
}
</style>
