<template>
  <div class="header_layout">
    <div class="header_main">
      <img src="@/assets/header_logo.png" alt="" class="header_l" />
      <div class="header_r">
        <div class="nav_item" :class="navActive === 'home' ? 'nav_item_active' : ''" @click.stop="routePush('home')">首页</div>
        <div class="nav_item" :class="navActive === 'client' ? 'nav_item_active' : ''" @click.stop="routePush('client')">客户展示</div>
        <div class="nav_item" :class="navActive === 'dynamic' ? 'nav_item_active' : ''" @click.stop="routePush('dynamic')">行业动态</div>
        <div class="nav_item" :class="navActive === 'relation' ? 'nav_item_active' : ''" @click.stop="routePush('relation')">联系我们</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { ref, nextTick } from 'vue'
const router = useRouter()
const navActive = ref<string>('home')

const routePush = path => {
  navActive.value = path
  router.push({ name: path })

  nextTick(() => {
    window.scrollTo(0, 0)
  })
}

const href = window.location.pathname.split('/')
if (href && href.length && href[1] && href[1].length) {
  navActive.value = href[1]
} else {
  navActive.value = 'home'
}
</script>

<style lang="scss">
.header_layout {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  box-sizing: border-box;
  .header_main {
    max-width: 80%;
    min-width: 1200PX;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: .8rem 0;
    box-sizing: border-box;
    .header_l {
      width: 6.11987rem;
    }
    .header_r {
      display: flex;
      align-items: center;
      font-size: .5rem;
      color: #6B6D71;
      .nav_item {
        margin-right: 1rem;
        padding: .2rem .3rem;
        border-radius: 6PX;
        font-weight: 500;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
      .nav_item_active {
        background-color: #5D5FEF;
        color: #fff;
      }
    }
  }
}
</style>
