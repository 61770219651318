import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios'
import { ElLoading } from 'element-plus'
import { LoadingInstance } from 'element-plus/es/components/loading/src/loading'

const baseURL = 'https://fireapi.huapao.cn'

interface HRequestInterceptors<T = AxiosResponse> {
   
  // 请求拦截器（成功与失败）
  requestInterceptors?: (config: AxiosRequestConfig) => AxiosRequestConfig;
  requestInterceptorsCatch?: (error: any) => any;
  // 相应拦截器（成功与失败） 
  responseInterceptor?: (res: T) => T;
  responseInterceptorCatch?: (error: any) => any;
}

// 继承接口: 定义每个请求的拦截器并且设置请求状态显示
interface HRequestConfig<T = AxiosResponse> extends AxiosRequestConfig {
  interceptors?: HRequestInterceptors<T>;
  // 是否展示请求加载状态
  showLoading?: boolean;
}

const DEFAULT_LOADING = false

class HRequest {
  // 类型
  instance: AxiosInstance;
  interceptors?: HRequestInterceptors;
  showLoading: boolean;
  loading?: LoadingInstance;

  constructor(config: HRequestConfig) {
  
    // 创建请求
    this.instance = axios.create(config);

    // 保存基本信息
    this.interceptors = config.interceptors;

    this.showLoading = config.showLoading ?? DEFAULT_LOADING;

    // 使用拦截器
    // 1.从config中获取的拦截器是对应的实例的拦截器
    this.instance.interceptors.request.use(this.interceptors?.requestInterceptorsCatch)
    // 响应拦截类型
    this.instance.interceptors.response.use(this.interceptors?.responseInterceptor, this.interceptors?.responseInterceptorCatch)

    // 2.所有示实例的请求拦截
    this.instance.interceptors.request.use(
      config => {
        if (this.showLoading) {
          this.loading = ElLoading.service({
            lock: true,
            text: '请求加载中...',
            background: 'rgba(255,255,255,0.5)'
          })
        }
        return config
      },
      err => {
        return err
      }
    )
    // 所有实例响应拦截
    this.instance.interceptors.response.use(
      res => {
        // 清除loading
        this.loading?.close()
        
        return res
      },
      err => {
        // 4xx -> 5xx 在这里拦截
        if (err.response.status == 404) {
          console.log('404 的错误')
        }
        return err
      }
    )
  }

  request<T>(config: HRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      // 1. 单个请求的cofig 的处理
      if (config.interceptors?.requestInterceptors) {
        config = config.interceptors.requestInterceptors(config);
      }
      // 判断是否显示loading
      if (config.showLoading) {
        this.showLoading = config.showLoading;
      }
      this.instance
        .request<any, T>(config)
        .then((res) => {
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          // 不影响下一个loading 的使用
          this.showLoading = DEFAULT_LOADING
          // 返回结果
          resolve(res)
        })
        .catch((err) => {
          // 不影响下一个loading 的使用
          this.showLoading = DEFAULT_LOADING
          reject(err)
        })
    })
  }
}

const request = new HRequest({
  baseURL,
  timeout: 6000
})

export default request
