import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DynamicView from '../views/DynamicView.vue'
import RelationView from '../views/RelationView.vue'
import ClientView from '../views/ClientView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: HomeView
      },
      {
        path: '/dynamic',
        name: 'dynamic',
        component: DynamicView
      },
      {
        path: '/relation',
        name: 'relation',
        component: RelationView
      },
      {
        path: '/client',
        name: 'client',
        component: ClientView
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
