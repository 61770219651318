import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/client_swiper.png'


const _hoisted_1 = { class: "client_layout" }
const _hoisted_2 = { class: "logo_list" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "bottom_layout" }
const _hoisted_5 = { class: "main" }
const _hoisted_6 = { class: "client_list" }
const _hoisted_7 = ["src"]

import { getClientList } from '@/api'
  import { ElMessage } from 'element-plus'
  import { ref } from 'vue'
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'ClientView',
  setup(__props) {

  const tList = ref<Array>([])
  const bList = ref<Array>([])
    
  const getArticleLists = async type => {
    const { data: res } = await getClientList({}, { page: 1, pageSize: 999, type })
    if (res.code === 1) {
      if (type === 1) {
        const list = [[]]
        res.result.records.forEach((item, index) => {
          if (list[list.length - 1].length >= 6) {
            list[list.length] = [item]
          } else {
            list[list.length - 1].push(item)
          }
        })
        tList.value = list
      }
      if (type === 2) bList.value = res.result.records
    } else {
      ElMessage.error(res.error && res.error.length ? res.error : '获取失败')
    }
  }
  
  getArticleLists(1)
  getArticleLists(2)
  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("img", {
      src: _imports_0,
      class: "client_swiper"
    }, null, -1)),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "title" }, [
      _createElementVNode("span", null, "入驻客户"),
      _createElementVNode("span", null, "Settled customer")
    ], -1)),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "tips" }, "入驻客户超2万家，这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案本次大赛由湘赣边烟花爆竹产业发展委员会湖南省工艺美术协会烟花爆竹专业", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "list_l" }, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tList.value, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "list_item",
          key: index
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (z, i) => {
            return (_openBlock(), _createElementBlock("img", {
              src: z.logo,
              class: "logo_icon",
              key: i,
              style: _normalizeStyle({ transform: `translateX(${tList.value.length - index}rem)` })
            }, null, 12, _hoisted_3))
          }), 128))
        ]))
      }), 128)),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "list_r" }, null, -1))
    ]),
    _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"scheme_layout\"><div class=\"scheme_l\"><div>企业解决方案</div><div>个性化私人定制</div><div>专业技术团队支持</div><div>战略合作部署</div><div>立即咨询</div></div><div class=\"scheme_r\"><div class=\"z_\"><div>优势文案</div><div>根据你企业的具体情况进行深度定制。从业务流程的优化到技术平台的搭建，我们将与你紧密合作，确保每一个环节都能精准地满足你企业的发展目标。</div></div><div style=\"width:1PX;height:3.1545rem;background-color:#E8E8E8;\"></div><div class=\"z_\"><div>优势文案</div><div>根据你企业的具体情况进行深度定制。从业务流程的优化到技术平台的搭建，我们将与你紧密合作，确保每一个环节都能精准地满足你企业的发展目标。</div></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "title" }, [
          _createElementVNode("span", null, "服务客户"),
          _createElementVNode("span", null, "Settled customer")
        ], -1)),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "tips" }, "企业建站是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案这里是文案本次大赛由湘赣边烟花爆竹产业发展委员会湖南省工艺美术协会烟花爆竹专业", -1)),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bList.value, (item, index) => {
            return (_openBlock(), _createElementBlock("img", {
              src: item.logo,
              class: "client_item",
              key: index
            }, null, 8, _hoisted_7))
          }), 128))
        ])
      ])
    ])
  ]))
}
}

})