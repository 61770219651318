<template>
  <div class="relation_layout">
    <img src="@/assets/relation_swiper.png" class="relation_swiper" />
    <div class="title">期待您的上门合作</div>
    <div class="tips">湖南省长沙市浏阳市浏阳大道116烟花贸易大厦902</div>
    <div class="map_layout">
      <img src="@/assets/map_swiper.png" class="map_swiper" />
    </div>
    <div class="bottom_layout">
      <div class="bottom_l">
        <div class="z">浏阳市星奇网络科技有限公司</div>
        <div class="tips_item">
          <img src="@/assets/relation_phone_icon.png" class="relation_icon" />
          <span>联系电话：</span>
          <span>155 8004 8888</span>
        </div>
        <div class="tips_item">
          <img src="@/assets/relation_user_icon.png" class="relation_icon" />
          <span>联系人：</span>
          <span>刘先生</span>
        </div>
        <div class="tips_item">
          <span style="padding-left: 1.0741rem;">微信号：</span>
          <span>hjdhejw2345</span>
        </div>
      </div>
      <div class="bottom_c" />
      <div class="bottom_r">
        <img src="@/assets/mpweixin_code.png" class="mpweixin_code" />
        <div>扫码进入小程序</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss">
.relation_layout {
  width: 100%;
  .relation_swiper {
    width: 100%;
    height: auto;
  }
  .title {
    padding: 1.8927rem 0 .6309rem;
    font-size: 1.51419rem;
    font-weight: 600;
    color: #1D2129;
    text-align: center;
  }
  .tips {
    font-size: .6309rem;
    color: #4E5969;
    text-align: center;
    padding-bottom: 1.2618rem;
  }
  .map_layout {
    width: 45.4258rem;
    margin: 0 auto;
    .map_swiper {
      width: 100%;
    }
  }
  .bottom_layout {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 2.7129rem 0 1.8927rem;
    .bottom_l {
      .z {
        font-size: .9463rem;
        color: #1D2129;
        font-weight: 600;
        margin-bottom: 1.2618rem;
      }
      .tips_item {
        display: flex;
        align-items: center;
        font-size: .5047rem;
        color: #4E5969;
        margin-bottom: .6309rem;
        .relation_icon {
          width: .7571rem;
          height: .7571rem;
          margin-right: .317rem;
        }
      }
    }
    .bottom_c {
      width: 1PX;
      height: 5.8675rem;
      background-color: #A9ABAF;
      margin: 0 3.0914rem 0 6.309rem;
    }
    .bottom_r {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-size: .5047rem;
      font-weight: 400;
      color: #5D5FEF;
      .mpweixin_code {
        width: 5.205rem;
        height: 5.205rem;
        margin-bottom: .6309rem;
      }
    }
  }
}
</style>
