const baseSize = 32

function setRem() {
  const clientWidth = document.documentElement.clientWidth
  const scale = document.documentElement.clientWidth / (clientWidth > 1050 ? 1920 : 750)
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
  document.body.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
}

setRem()

window.onresize = () => {
  setRem()
}
