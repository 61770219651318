import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/header_logo.png'


const _hoisted_1 = { class: "header_layout" }
const _hoisted_2 = { class: "header_main" }
const _hoisted_3 = { class: "header_r" }

import { useRouter } from 'vue-router'
import { ref, nextTick } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'headerLayout',
  setup(__props) {

const router = useRouter()
const navActive = ref<string>('home')

const routePush = path => {
  navActive.value = path
  router.push({ name: path })

  nextTick(() => {
    window.scrollTo(0, 0)
  })
}

const href = window.location.pathname.split('/')
if (href && href.length && href[1] && href[1].length) {
  navActive.value = href[1]
} else {
  navActive.value = 'home'
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        class: "header_l"
      }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["nav_item", navActive.value === 'home' ? 'nav_item_active' : '']),
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (routePush('home')), ["stop"]))
        }, "首页", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["nav_item", navActive.value === 'client' ? 'nav_item_active' : '']),
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (routePush('client')), ["stop"]))
        }, "客户展示", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["nav_item", navActive.value === 'dynamic' ? 'nav_item_active' : '']),
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (routePush('dynamic')), ["stop"]))
        }, "行业动态", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["nav_item", navActive.value === 'relation' ? 'nav_item_active' : '']),
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (routePush('relation')), ["stop"]))
        }, "联系我们", 2)
      ])
    ])
  ]))
}
}

})